import { Typography } from "@material-ui/core";
import React from "react";

export default function WelcomePage(props) {
  return (
    <div>
      <h1>Welcome Page</h1>
      <p>
        <h2>This is the on-boarding page.</h2>
        <Typography>
          <a href="">Go to list of Placement Order</a>
        </Typography>
        <Typography>
          <a href="">Create a new Placement Order</a>
        </Typography>
      </p>
    </div>
  );
}
