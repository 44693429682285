import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    width: "100%",
    border: "solid 1px #757263",
    padding: theme.spacing(0.5),
  },
  table: {
    tableLayout: "fixed",
  },
  td: {
    width: "50%",
  },
  tableHeader: {
    width: "50%",
    color: "white",
    backgroundColor: "black",
  },
  sectionHeader: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#757263",
    paddingTop: theme.spacing(2),
  },
  sTitle: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#5E5B70",
    // borderBottom: "solid 1px #C7C7C9",
    // paddingBottom: theme.spacing(1),
  },
  spacingX: {
    padding: theme.spacing(0, 3.5),
  },
  orders: {
    marginTop: theme.spacing(2),
  },
  orderListUl: {
    padding: 0,
    margin: 0,
  },
  orderList: {
    listStyle: "none",
    padding: theme.spacing(2.5, 0),
    borderBottom: "solid 1px #C7C7C9",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    "&:first-of-type": {
      paddingTop: 0,
    },
  },
  recruitingOrderEmpty: {
    backgroundImage: "url(/static/images/empty-box.svg)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "350px",
    height: "100%",
    width: "100%",
  },
  orderLink: {
    fontSize: "16px",
    fontWeight: "600",
    width: "calc(100% - 50px)",
    color: "inherit",
  },
  subText: {
    display: "flex",
    fontSize: "14px",
    color: "#5E5B70",
    marginTop: theme.spacing(1.2),
    paddingRight: theme.spacing(5),
    width: "100%",
    "& span": {
      fontWeight: 600,
      marginRight: theme.spacing(0.5),
    },
  },
  noBulletList: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
}));

function OrderBlock(props) {
  const { classes, blockTitle, items } = props;

  return (
    <Typography className={classes.sTitle}>
      <>
        <Typography className={classes.sTitle}>{blockTitle}</Typography>
        <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
          {items.map((item) => (
            <li key={item.key + "-" + item.value}>{item.value}</li>
          ))}
        </ul>
      </>
    </Typography>
  );
}

function OrderPairedBlock(props) {
  const { classes, blockTitle, itemsPrimary, itemsSecondary } = props;
  return (
    <Typography className={classes.sTitle}>
      <>
        <Typography className={classes.sTitle}>{blockTitle}</Typography>
        <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
          {itemsPrimary.map((item, index) => (
            <li key={itemsPrimary[index].key}>
              {itemsPrimary[index].value} -{" "}
              {itemsSecondary[index] ? itemsSecondary[index].value : ""}
            </li>
          ))}
        </ul>
      </>
    </Typography>
  );
}

function OrdersList(props) {
  const {
    nurseFirstName,
    nurseLastName,
    nurseEmail,
    nursePhoneNumber,
    nursePosition,
    nurseLocationZipcode,
    nurseLicensureState,
    nurseCertification,
    nurseExperience,
    nurseEducation,
    nurseAreaOfFocus,
    nurseBenefits,
    nurseTimingPreference,
    nurseSchedulePreference,
    nurseShiftPreference,
    nurseDegree,
    nurseEMRExperience,
    nurseMinHourlyRate,
    nursePairedExperienceDuration,
    nursePairedExperiencePosition,
    nurseMinimumBonus,
    employchecked,
  } = props;

  // console.log("nursePairedExperiencePosition", nursePairedExperiencePosition);
  // console.log("nursePairedExperienceDuration", nursePairedExperienceDuration);

  const classes = useStyles();

  return (
    <div className={classes.spacingX}>
      <Typography className={classes.sectionHeader}>
        Step 1 - This is my contact information and current position
      </Typography>

      <div className={classes.sectionContainer}>
        <table style={{ width: "100%", height: "100%" }}>
          <thead>
            <td className={classes.tableHeader}>Attribute</td>
            <td className={classes.tableHeader}>Value</td>
          </thead>
          <tbody>
            <tr>
              <td style={{ backgroundColor: "#E1CCBE", color: "#000000" }}>
                Name:
              </td>
              <td>{`${nurseFirstName} ${nurseLastName}`}</td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#E1CCBE", color: "#000000" }}>
                Phone:
              </td>
              <td>{nursePhoneNumber}</td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#E1CCBE", color: "#000000" }}>
                Email:
              </td>
              <td>{nurseEmail}</td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#E1CCBE", color: "#000000" }}>
                Current Position:
              </td>
              <td>{nursePosition?.value ? nursePosition?.value : ""}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* <div className={classes.sectionContainer}>
        <Typography className={classes.sTitle}>
          {nurseFirstName == (undefined || nurseFirstName.length === 0) ||
          nurseLastName == (undefined || nurseLastName.length === 0) ? (
            ""
          ) : (
            <span>
              Name: {`${nurseFirstName} ${nurseLastName}`}
              <br />
            </span>
          )}
          {nursePhoneNumber == undefined ? (
            ""
          ) : (
            <span>
              {" "}
              {`Phone: ${nursePhoneNumber}  `}
              <br />
            </span>
          )}
          {nurseEmail == undefined ? (
            ""
          ) : (
            <span>
              {" "}
              {`Email: ${nurseEmail}`}
              <br />
            </span>
          )}
        </Typography>
        <OrderBlock
          classes={classes}
          blockTitle="Current Position"
          items={nursePosition}
        />
        <Typography className={classes.sTitle}>
          {`Current Position: ${
            nursePosition?.value ? nursePosition?.value : ""
          }`}
        </Typography>
      </div> */}

      <Typography className={classes.sectionHeader}>
        Step 2 - This is what I am looking for in my next position
      </Typography>

      <div className={classes.sectionContainer}>
        <table style={{ width: "100%", height: "100%" }}>
          <thead>
            <td className={classes.tableHeader}>Attribute</td>
            <td className={classes.tableHeader}>Value</td>
          </thead>
          <tbody>
            <tr>
              <td style={{ backgroundColor: "#E1CCBE", color: "#000000" }}>
                Peferred Location:
              </td>
              <td>{nurseLocationZipcode}</td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#E1CCBE", color: "#000000" }}>
                Area of Focus:
              </td>
              <td>
                <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                  {nurseAreaOfFocus.map((item) => (
                    <li key={item.key + "-" + item.value}>{item.value}</li>
                  ))}
                </ul>
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#E1CCBE", color: "#000000" }}>
                Min. Hourly Rate:
              </td>
              <td>${`${nurseMinHourlyRate}`} / hour</td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#E1CCBE", color: "#000000" }}>
                Min. Bonus:
              </td>
              <td>${`${nurseMinimumBonus}`}</td>
            </tr>

            <tr>
              <td style={{ backgroundColor: "#E1CCBE", color: "#000000" }}>
                Benefits:
              </td>
              <td>
                <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                  {nurseBenefits.map((item) => (
                    <li key={item.key + "-" + item.value}>{item.value}</li>
                  ))}
                </ul>
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#E1CCBE", color: "#000000" }}>
                Timing Preference:
              </td>
              <td>
                {nurseTimingPreference?.value
                  ? nurseTimingPreference?.value
                  : ""}
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#E1CCBE", color: "#000000" }}>
                Schedule Preference:
              </td>
              <td>
                <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                  {nurseSchedulePreference.map((item) => (
                    <li key={item.key + "-" + item.value}>{item.value}</li>
                  ))}
                </ul>
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#E1CCBE", color: "#000000" }}>
                Shift Preference:
              </td>
              <td>
                <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                  {nurseShiftPreference.map((item) => (
                    <li key={item.key + "-" + item.value}>{item.value}</li>
                  ))}
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <Typography className={classes.sectionHeader}>
        Step 3 - This is what I have to offer
      </Typography>
      <div className={classes.sectionContainer}>
        <table style={{ width: "100%", height: "100%" }}>
          <thead>
            <td className={classes.tableHeader}>Attribute</td>
            <td className={classes.tableHeader}>Value</td>
          </thead>
          <tbody>
            <tr>
              <td style={{ backgroundColor: "#E1CCBE", color: "#000000" }}>
                Licensure
              </td>
              <td>
                <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                  {nurseLicensureState.map((item) => (
                    <li key={item.key + "-" + item.value}>{item.value}</li>
                  ))}
                </ul>
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "#E1CCBE", color: "#000000" }}>
                Education
              </td>
              <td>{nurseEducation?.value ? nurseEducation?.value : ""}</td>
            </tr>

            <tr>
              <td style={{ backgroundColor: "#E1CCBE", color: "#000000" }}>
                Degree
              </td>
              <td>
                <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                  {nurseDegree.map((item) => (
                    <li key={item.key + "-" + item.value}>{item.value}</li>
                  ))}
                </ul>
              </td>
            </tr>

            <tr>
              <td style={{ backgroundColor: "#E1CCBE", color: "#000000" }}>
                Certification
              </td>
              <td>
                <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                  {nurseCertification.map((item) => (
                    <li key={item.key + "-" + item.value}>{item.value}</li>
                  ))}
                </ul>
              </td>
            </tr>

            <tr>
              <td style={{ backgroundColor: "#E1CCBE", color: "#000000" }}>
                Work Experience
              </td>
              <td>
                <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                  {nursePairedExperienceDuration.map((item, index) => {
                    if (item) {
                      return (
                        <li key={item.key + "-" + item.value}>
                          {nursePairedExperiencePosition[index]
                            ? nursePairedExperiencePosition[index].value
                            : ""}{" "}
                          - {item.value}
                        </li>
                      );
                    } else {
                      return <li></li>;
                    }
                  })}
                </ul>
              </td>

              <td></td>
            </tr>

            <tr>
              <td style={{ backgroundColor: "#E1CCBE", color: "#000000" }}>
                EMR Experience
              </td>
              <td>
                <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                  {nurseEMRExperience.map((item) => (
                    <li key={item.key + "-" + item.value}>{item.value}</li>
                  ))}
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OrdersList;
