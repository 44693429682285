import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Item from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#253551",
    marginTop: theme.spacing(3),
    padding: theme.spacing(7, 0, 3, 0),
    width: "100%",
  },
  flinks: {
    fontSize: "16px",
    color: "#fff",
  },
  linkTitle: {
    fontSize: "16px !important",
    fontWeight: "700",
    color: "#fff",
  },
  navLink: {
    fontSize: "16px",
    color: "#fff",
    display: "flex",
    marginTop: theme.spacing(2),
  },
  socialBtn: {
    color: "#fff",
    marginRight: theme.spacing(2.5),
    marginTop: theme.spacing(1.25),
    display: "inline-block",
    "&:last-of-type": {
      marginRight: 0,
    },
  },
  copyrights: {
    borderTopWidth: "1px",
    borderTopColor: "#5E5B70",
    borderTopStyle: "solid",
    justifyContent: "center",
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(2.2),
    display: "flex",
  },
  copyrightsText: {
    fontSize: "14px",
    color: "#A2A0B0",
    fontWeight: "300",
  },
  poweredBy: {
    justifySelf: "flex-end",
    marginLeft: "auto",
    fontSize: "14px",
    color: "#A2A0B0",
    fontWeight: "300",
  },
  customWidth: {
    "&.MuiContainer-maxWidthLg": {
      maxWidth: "1150px",
      padding: 0,
    },
  },
  footerCol1: {
    width: "209px",
    marginRight: "175px",
  },
  footerCol2: {
    width: "102px",
    marginRight: "175px",
  },
  footerCol3: {
    width: "204px",
    marginRight: "175px",
  },
  footerCol4: {
    width: "97px",
  },
  logoimg: {
    marginTop: "20px",
    width: "136px",
  },
}));

const links1 = [
  {
    to: "/Faq",
    name: "Frequently Asked Questions",
  },
];
const links2 = [
  {
    to: "/AboutUs",
    name: "About Us",
  },
  {
    to: "/ContactUs",
    name: "Contact Us",
  },
  {
    to: "/EULA",
    name: "EULA",
  },
];
const links3 = [];

export default function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <Container fixed className={classes.customWidth}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography className={classes.linkTitle}>Learn More</Typography>
            {links1.map((link, index) => (
              <Link
                key={index}
                className={classes.navLink}
                to={link.to}
                target="_blank"
                component={RouterLink}
              >
                {link.name}
              </Link>
            ))}
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.linkTitle}>Company</Typography>
            {links2.map((link, index) => (
              <Link
                key={index}
                className={classes.navLink}
                to={link.to}
                target="_blank"
                component={RouterLink}
              >
                {link.name}
              </Link>
            ))}
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.linkTitle}>Follow Us</Typography>
            <Link
              className={classes.socialBtn}
              href="https://www.facebook.com/people/Nurse-ProLink/61554401429617/"
            >
              <FacebookIcon fontSize="small" />
            </Link>
            <Link className={classes.socialBtn} href="#">
              <TwitterIcon fontSize="small" />
            </Link>
            <Link
              className={classes.socialBtn}
              href="http://linkedin.com/company/nurse-prolink/?viewAsMember=true"
            >
              <Icon>
                <img
                  className={classes.imageIcon}
                  src="/static/images/Blog.svg"
                />
              </Icon>
            </Link>
          </Grid>
        </Grid>

        <Grid container className={classes.copyrights}>
          <Typography className={classes.copyrightsText}>
            Copyright © Placement Loop, Inc. 2024. All Rights Reserved.
          </Typography>
          <Typography className={classes.poweredBy}>
            Powered by Placement Loop
          </Typography>
        </Grid>
      </Container>
    </div>
  );
}
