import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import "react-perfect-scrollbar/dist/css/styles.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Header from "../../component/Header/Header";
import { userRoles } from "../../utils";
import { config } from "../../config/config";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    backgroundColor: "#fff",
    borderRadius: "0 10px 10px 10px",
    boxShadow: "0 3px 6px rgba(0,0,0,.16)",
    padding: theme.spacing(3.5),
    position: "relative",
    width: "1150px",
    margin: "0 auto",
    marginBottom: 30,
    "&:after": {
      content: '""',
      width: "2px",
      height: "48px",
      backgroundColor: "#6C59B4",
      position: "absolute",
      left: "0",
      top: "0",
    },
    "& .MuiTypography-subtitle1": {
      fontWeight: 600,
      marginBottom: "10px !important",
    },
    "& .MuiTypography-subtitle2": {
      fontWeight: "bold",
      marginBottom: "10px !important",
    },
    "& .MuiTypography-root": {
      marginBottom: 20,
      "& a": {
        color: "#6C59B4",
        textDecoration: "none",
      },
    },
    "& ul": {
      margin: 0,
      paddingLeft: 0,
      "& li": {
        fontSize: 14,
        listStyleType: "none",
        paddingBottom: 20,
        "& >span": {
          paddingLeft: 28,
          paddingRight: 12,
        },
      },
    },
    "& ul .MuiTypography-subtitle1 span": {
      paddingLeft: 0,
    },
  },
  bannerBg: {
    backgroundImage: "url(/static/images/banner.png)",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "right",
    backgroundPositionY: "-50px",
    minHeight: "508px",
  },
  pageBg: {
    backgroundImage: "url(/static/images/page-bg.png)",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "right",
    backgroundPositionY: "-50px",
  },
  pageTitle: {
    fontSize: "18px",
    fontWeight: 600,
    margin: "0 0 20px 0",
  },
  scrollBox: {
    maxHeight: "500px",
    position: "relative",
    paddingRight: theme.spacing(1.5),
  },
  table: {
    marginBottom: 30,
    width: "75%",
    "& .MuiTableCell-head": {
      fontWeight: "bold",
    },
    "& .MuiTableCell-root": {
      border: "1px solid rgba(224, 224, 224, 1)",
      verticalAlign: "top",
      padding: "16px !important",
      width: "50%",
    },
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
    padding: "0 40px",
  },
  inlineDiv: {
    display: "inline-flex",
    "& .MuiTypography-root": {
      fontWeight: 600,
    },
  },
  button: {
    backgroundColor: "#6C59B4",
    padding: theme.spacing(1, 2.5),
    borderRadius: "20px",
    color: "#fff",
    textTransform: "inherit",
    border: 0,
    outline: "none",
    cursor: "pointer",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: " #493E75",
    },
  },
  subtitle3: {
    fontWeight: "bold",
    marginBottom: "10px !important",
    fontSize: "1rem",
    margin: "0 auto",
    textAlign: "center",
  },
  textCenter: {
    textAlign: "center",
  },
  bold: {
    fontWeight: "bold",
  },
  padTop: {
    paddingTop: 15,
  },
  noPad: {
    padding: "0px !important",
  },
  padRight: {
    paddingRight: 12,
    paddingLeft: "0px !important",
  },
  underLine: {
    textDecorationLine: "underline",
  },
}));

function EULAPolicy() {
  const classes = useStyles();
  const [state, setStates] = React.useState(false);
  const handleChangeCheckbox = (event) => {
    state ? setStates(false) : setStates(true);
  };

  return (
    <Container fixed>
      <Box className={`${classes.bannerBg} ${classes.pageBg}`}>
        <Header />
        <Box className={classes.mainWrapper}>
          <h2
            className={`${classes.pageTitle} ${classes.textCenter} ${classes.bold}`}
          >
            Loop Builder LLC
          </h2>
          <h2
            className={`${classes.pageTitle} ${classes.textCenter} ${classes.bold}`}
          >
            SAAS End User License Agreement
          </h2>
          <Box className={classes.textCenter}>
            <Typography>
              THIS SAAS END USER LICENSE AGREEMENT (THIS "EULA") SETS FORTH EACH
              END-USER'S RIGHTS TO ACCESS AND USE THE SOFTWARE, SOLELY ON A
              SOFTWARE-AS-A-SERVICE BASIS (THE "SERVICE"). THE SERVICE IS
              PROVIDED BY LOOP BUILDER LLC, A NORTH CAROLINA LIMITED LIABILITY
              COMPANY (THE "COMPANY"), THROUGH THE WEBSITE HOSTED BY LOOP
              BUILDER, LLC, A DELAWARE LIMITED LIABILITY COMPANY ("LOOP
              BUILDER"), FOR OR ON BEHALF OF THE COMPANY.
            </Typography>
            <Typography>
              COMPANY AGREES TO LET YOU USE THE SERVICE ONLY ON THE CONDITION
              THAT YOU ACCEPT ALL OF THE TERMS AND CONDITIONS OF THIS EULA AND
              THE PRIVACY POLICY (DEFINED BELOW). THIS EULA AND THE PRIVACY
              POLICY ARE LEGAL AND ENFORCEABLE CONTRACTS BETWEEN YOU AND
              COMPANY. YOU ACCEPT, CONSENT TO, AND AGREE TO BE BOUND BY THE
              TERMS AND CONDITIONS OF THIS EULA, AND TO THE COLLECTION, USE,
              SHARING, STORAGE, AND TRANSMISSION OF YOUR INFORMATION AS OUTLINED
              IN THE PRIVACY POLICY, BY (A) REGISTERING FOR, SUBSCRIBING TO,
              USING OR OTHERWISE ACCESSING THE SERVICE, OR (B) CLICKING THE
              "AGREE" OR "ACCEPT" (OR SIMILAR) BUTTON, OR (C) OTHERWISE
              MANIFESTING ASSENT.{" "}
              <span className={classes.bold}>
                IF AT ANY TIME YOU DO NOT AGREE TO THE TERMS AND CONDITIONS OF
                THIS EULA OR THE PRIVACY POLICY, YOUR RIGHT AND LICENSE TO USE
                THE SERVICE SHALL IMMEDIATELY TERMINATE, AND YOUR SOLE REMEDY
                SHALL BE TO STOP ACCESSING OR USING THE SERVICE.
              </span>{" "}
              YOU REPRESENT TO US THAT YOU HAVE THE CAPACITY AND AUTHORITY TO
              ACCEPT THIS EULA AND THE PRIVACY POLICY.
            </Typography>
            <Typography>
              IF YOU ARE A MINOR, YOU ARE PROHIBITED FROM USING THE SERVICE
              UNLESS YOUR LEGAL GUARDIAN CONSENTS TO, AND ACCEPTS THE TERMS AND
              CONDITIONS OF, THIS EULA AND THE PRIVACY POLICY. IN ADDITION,
              COMPANY DOES NOT PERMIT REGISTRATION BY, AND WILL NOT KNOWINGLY
              COLLECT, STORE, OR SHARE PERSONAL INFORMATION FROM, ANYONE UNDER
              13 YEARS. Subject to the foregoing, if you are a minor, references
              to "you" and the like herein include your legal guardian.
            </Typography>
          </Box>
          <ul>
            <li>
              <Typography variant="subtitle1">
                <span>1.</span> Service; License; Limitations.
              </Typography>
              <ul>
                <li>
                  <span>1.1.</span> Company hereby grants you a non-exclusive,
                  non-transferable, non-sublicensable, revocable license and
                  right to access the Service, on a software-as-a-service,
                  runtime basis only, solely during the term of your (or your
                  employer’s or other principal’s) subscription to the Service
                  with Company (or with a Substitute Provider, as defined below)
                  as provided in the applicable separate contracts, purchase
                  orders, Hosted Contract (defined below), or other documents
                  ("Subscription Documents") between you (or your employer or
                  other principal) and the Company, or between you (or your
                  employer or other principal) and a third party (such as in the
                  case of Hosted Contracts). The foregoing license is granted
                  subject to payment of all applicable fees and other charges
                  pursuant to the Subscription Documents. For clarification,
                  "Subscription Documents" includes documents that may include
                  terms related to services other than your subscription for the
                  Service, and/or terms solely between you and a third party.
                  All rights not expressly granted to you hereunder are reserved
                  by Company and its licensors.
                </li>
                <li>
                  <span>1.2.</span> The Service is provided on servers and other
                  equipment owned, leased, or otherwise controlled by Company or
                  Loop Builder, and no hardware or equipment is being
                  transferred to you in any respect. You are responsible for
                  maintaining your own web browsing devices, connectivity, and
                  other systems capable of accessing the Service.
                </li>
                <li>
                  <span>1.3.</span> Company or Loop Builder may host a contract
                  to be signed between you and a third party (a "Hosted
                  Contract"). You represent and warrant that you have either
                  chosen or independently agreed to the terms and conditions of
                  such contract and you agree that the Company's or Loop
                  Builder's hosting of such a contract is for your convenience
                  only and does not represent the provision of legal advice. You
                  are solely responsible for the terms and conditions of the
                  Hosted Contract and to have your own attorney review the same.
                  Notwithstanding the inclusion in a Hosted Contract of any
                  terms of your subscription for the Service, neither Company
                  nor Loop Builder are a party to any Hosted Contract and you
                  agree to pursue solely the counter-party to any Hosted
                  Contract to enforce the terms thereof, and hereby release and
                  covenant not to sue Company and Loop Builder in respect of any
                  Hosted Contract.
                </li>
                <li>
                  <span>1.4.</span> Company may process payment by payment card,
                  or by ACH, using payment information provided by you or your
                  customer, for your or such customer's subscription to the
                  Service and/or other services pursuant to the Subscription
                  Documents. You hereby authorize (and if applicable you
                  represent, warrant, and covenant to obtain your customer's
                  authorization for) Company to process, from time to time
                  pursuant to the Subscription Documents, all amounts (a) due to
                  the Company under Subscription Documents, and/or (b) any
                  amounts for services other than your subscription for the
                  Service which are due from you under a Hosted Contract, as
                  applicable, using the payment card or ACH information
                  provided. You agree that the foregoing includes the processing
                  of recurring charges to the extent provided for in the
                  Subscription Documents. You agree that all amounts processed
                  are non-refundable and that neither Company nor Loop Builder
                  has any obligation or liability to refund or return any
                  amounts paid pursuant to the Subscription Documents, including
                  any amounts paid pursuant to a Hosted Contract for services
                  other than your subscription for the Service. You agree to
                  keep all payment card or other payment information current and
                  up to date until all amounts due and owning have been paid.
                </li>
                <li>
                  <span>1.5.</span>{" "}
                  <span className={classes.noPad}>Your Responsibilities.</span>
                  <ul className={classes.padTop}>
                    <li>
                      <span>(5.a)</span> You shall be solely responsible for
                      your relationships with, and use of the Service by or on
                      behalf of, any other end-users who access the Service on
                      your behalf or through your user account (each, a "User").
                      You shall cause each User to agree to the terms hereof,
                      and any breach of the terms and conditions of this
                      Agreement by a User shall be deemed a breach by you.
                    </li>
                    <li>
                      <span>(5.b)</span> You agree to provide complete and
                      accurate contact information to Company. If the contact
                      information you provide is false or fraudulent, Company
                      reserves the right to terminate your access to the
                      Service, in addition to any other legal remedies.
                    </li>
                    <li>
                      <span>(5.c)</span> You shall immediately notify Company
                      upon a breach of the terms and conditions of this
                      Agreement by any User, and you shall immediately suspend,
                      and assist Company in suspending, such User's access to
                      the Service to prevent or mitigate damage to the Service
                      or any legal liability accruing to Company or Loop Builder
                      with respect to such User's use of the Service. You shall
                      notify Company immediately of any unauthorized use of the
                      Service, or any other known or suspected breach of
                      security, and shall report to Company immediately any
                      unauthorized copying or distribution of any content
                      available on or through the Service ("Content").
                    </li>
                    <li>
                      <span>(5.d) </span>
                      You represent, warrant, and covenant to Company that you
                      and all Users shall comply with all applicable laws in
                      connection with your use of the Service, including but not
                      limited to laws related to: import and export restrictions
                      placed upon the Service; transmission and international
                      communication of technical or personal data; and the
                      collection, use, disclosure, processing, and security of
                      personal data.
                    </li>
                  </ul>
                </li>
                <li>
                  <span>1.6.</span> Prohibitions and Acceptable Use. You shall
                  not, and shall not permit Users to: (i) license, sublicense,
                  sell, resell, transfer, assign, distribute, or otherwise
                  commercially exploit or make available to any third party the
                  Service or the Content in any way; (ii) modify or make
                  derivative works based upon the Service or the Content; (iii)
                  create Internet "links" to the Service or "frame" or "mirror"
                  any Content on any other server or wireless or Internet-based
                  device; or (iv) reverse engineer the Service; (v) access the
                  Service in order to (a) build a competitive product or
                  service, (b) build a product using similar ideas, features,
                  functions or graphics of the Service, (c) copy any ideas,
                  features, functions or graphics of the Service, or (d) use the
                  Service on behalf of, or for the benefit of, any third party
                  unless such third party (x) is a then-current subscriber to,
                  or otherwise is validly licensed to use, the Service, and (y)
                  has agreed to, and is bound by, the terms of this EULA or
                  another license with the Company in respect of the use of the
                  Service; (vi) access the Service if you are a direct
                  competitor of Company, except with Company's prior written
                  consent; (vii) access the Service for purpose of monitoring
                  its availability, performance, or functionality, or for any
                  other benchmarking or competitive purpose; (viii) send spam or
                  otherwise duplicative or unsolicited messages; (ix) send or
                  store infringing, obscene, threatening, libelous, or otherwise
                  unlawful or tortious material, including material harmful to
                  children or violative of third party privacy rights; (x) send
                  or store material containing software viruses, worms, Trojan
                  horses, or other harmful computer code, files, scripts,
                  agents, or programs; (xi) interfere with or disrupt the
                  integrity or performance of the Service or the data contained
                  therein; (xii) attempt to gain unauthorized access to the
                  Service or its related systems or networks, or the data
                  contained therein; (xiii) transmit, load, disseminate or post
                  any information in violation of or noncompliance with Laws; or
                  (xv) impersonate any other user of the Service, or provide
                  false identity information to gain access to or use the
                  Service.
                </li>
              </ul>
            </li>

            <li>
              <Typography variant="subtitle1">
                <span>2.</span> Fees.
              </Typography>
              <ul>
                <li>
                  <span>2.1.</span> Payment Terms. You agree to pay any fees and
                  charges due for access to the Service ("Service Fees") in
                  accordance with the Subscription Documents. All payment
                  disputes must be submitted within 60 days of charge processing
                  or shall be deemed waived. Service Fees are exclusive of all
                  taxes, levies, or duties imposed by taxing authorities, and
                  you shall be responsible for payment of all such taxes,
                  levies, or duties, excluding only federal or state taxes based
                  solely on Company's income. As used herein, "Service Fees" do
                  not include any amounts due from you under a Hosted Contract
                  for services other than your subscription for the Service.
                </li>
                <li>
                  <span>2.2.</span> Nonpayment and Suspension. In addition to
                  any other rights of Company herein, Company reserves the right
                  to suspend or terminate your and each User's access to the
                  Service if your account becomes delinquent (all Service Fees
                  shall continue during any period of suspension). Delinquent
                  invoices and accounts are subject to interest of one percent
                  (1.0%) per month on any outstanding balance, or the maximum
                  permitted by law, whichever is less, plus all expenses of
                  collection (including without limitation reasonable attorney's
                  fees and expenses).
                </li>
              </ul>
            </li>
            <li>
              <Typography variant="subtitle1">
                <span>3.</span> Term.
              </Typography>
              <ul>
                <li>
                  <span>3.1.</span> Rights to Use the Service. Your right to
                  access the Service is solely during the term of your
                  subscription to the Service according to the Subscription
                  Documents, subject to earlier termination as provided herein
                  or in the Subscription Documents. In addition, and
                  notwithstanding anything to the contrary, the Company may
                  suspend or terminate your right to access the Service at any
                  time upon or after your breach of the terms hereof.
                </li>
                <li>
                  <span>3.2.</span> Effect of Termination. Upon termination of
                  this Agreement, you and each User shall immediately cease to
                  use the Service. Termination of this Agreement shall not
                  affect any Subscription Documents, except as specifically set
                  forth therein.
                </li>
                <li>
                  <span> 3.3.</span> Attornment. The Company shall have the
                  right to assign this Agreement, together with any Subscription
                  Documents, to Loop Builder or its designee (a "Substitute
                  Provider"). You agree to any such assignment and to attorn to
                  such Substitute Provider upon receiving notice thereof.
                  Thereupon, such Substitute Provider shall have the rights and
                  remedies of the Company hereunder or in the Subscription
                  Documents.
                </li>
                <li>
                  <span>3.4.</span> Survival. Those terms hereof (including
                  without limitation payment terms, limitations of liability,
                  proprietary rights, and confidentiality obligations) which, by
                  the nature thereof should survive any termination or
                  expiration hereof shall so survive, and any express reference
                  herein to the survival of any provisions hereof shall not
                  exclude the survival of any such other terms.
                </li>
              </ul>
            </li>
            <li>
              <Typography variant="subtitle1">
                <span>4.</span> Intellectual Property Ownership; Company Data.
              </Typography>
              <ul>
                <li>
                  <span>4.1.</span> Ownership of the Service. Company (and its
                  licensors, where applicable) shall exclusively own all right,
                  title, and interest in and to, and all intellectual property
                  rights (including patent, copyright, trade secret, and
                  trademark or service mark rights) worldwide in and to the
                  Service, hardware and software used to provide or host the
                  same, the Content, and any other technology or materials owned
                  by Company or made available to you or any User in the course
                  of using the Service, and any suggestions, ideas, enhancement
                  requests, feedback, recommendations, or other information
                  provided by you or any other party relating to the Service.
                  The Service is licensed, and not sold, to you subject to the
                  terms of this Agreement. This Agreement does convey to you any
                  rights of ownership in or to the Service, the Content, or any
                  other technology or materials owned by Company. Company's
                  name, Company's logo, and the product names associated with
                  the Service are trademarks of Company or third parties, and no
                  right or license is granted to use them.{" "}
                </li>
                <li>
                  <span>4.2. Company Data; Privacy Policy.</span>
                  <ul className={classes.padTop}>
                    <li>
                      <span>(2.a)</span> You agree to the privacy policy set
                      forth at the following URL www.
                      <code>&lt;url&gt;</code> (as amended, revised, or replaced
                      from time to time, the "Privacy Policy"). You represent
                      that you are able to access and read the Privacy Policy.
                      You accept, agree, and consent to Company's use, sharing,
                      storage, and transmission of your information according to
                      the Privacy Policy. Without limitation to the foregoing,
                      you agree, to the maximum extent allowed under applicable
                      law, that the Company shall own all data, information, and
                      Content you submit to the Service or which is submitted to
                      the Service pursuant to or in connection with any
                      Subscription Documents. You agree that the Privacy Policy
                      and the foregoing provision shall supersede any term in
                      any Subscription Documents or any other agreement to the
                      extent in conflict with the Privacy Policy or the
                      foregoing provision. You may only use the personal
                      information and data of others (including other users of
                      the Service) for purposes consistent with, and subject to,
                      the Privacy Policy (meaning that, as a user of such
                      information and data, you shall only collect, use, share,
                      store, or transmit such information and data in accordance
                      with the limitations, requirements, and obligations
                      described in the Privacy Policy which are applicable to
                      the Company), and may only use such information and data
                      in accordance with applicable laws and regulations,
                      including all laws related to the collection, use,
                      disclosure, processing, and security of personal, health,
                      or financial data, and no such data or information may be
                      used where such use would violate such laws. Except as
                      otherwise provided under this Agreement, you will not
                      transfer any such data or information outside of the
                      United States without the express written agreement of
                      Company.
                    </li>
                    <li>
                      <span>(2.b) </span>
                      With respect to data and information you submit (including
                      data and information you submit on behalf of others) to
                      the Service or obtain from the Service, you shall have the
                      sole responsibility for (i) the accuracy, quality,
                      integrity, legality, reliability, and appropriateness of
                      such data and information, and (ii) obtaining all rights
                      to use such data and information from the owner thereof,
                      and all intellectual property rights therein.
                    </li>
                    <li>
                      <span>(2.c)</span> Except as otherwise expressly provided
                      herein, as may be mandated by applicable laws, or as may
                      be provided in a separate written agreement between the
                      parties, Company shall not be responsible or liable for
                      the deletion, correction, destruction, damage, loss,
                      failure to store, or backup of any data or materials, and
                      Company is not responsible for the value of any lost data,
                      software, or content.
                    </li>
                    <li>
                      <span>(2.d)</span> Upon termination or expiration of this
                      Agreement, except as otherwise expressly provided herein
                      or as may be mandated by applicable Law, your right to
                      access or use data or materials through the Service or on
                      Company's systems ceases immediately. In addition, Company
                      reserves the right to withhold access to, or remove, data
                      and materials from Company's systems upon notice of
                      material breach, including without limitation Service Fee
                      non-payment.
                    </li>
                  </ul>
                </li>
                <li>
                  <span>4.3.</span> This{" "}
                  <span className={`${classes.underLine} ${classes.noPad}`}>
                    Section 4{" "}
                  </span>{" "}
                  &nbsp; shall survive any termination of the Agreement.
                </li>
              </ul>
            </li>
            <li>
              <span className={`${classes.padRight} ${classes.bold}`}>5.</span>
              Third Party Interactions. During use of the Service, you may enter
              into correspondence with, or otherwise interact with, third
              parties through the Service. Any such activity, and any terms,
              conditions, warranties, or representations associated with such
              activity, is solely between you and the applicable third party.
              Company and its licensors shall have no liability, obligation, or
              responsibility for any such correspondence, interaction, or
              transaction between you and any such third party. Company does not
              endorse any sites on the Internet that are linked through the
              Service. Company provides these links to you only as a matter of
              convenience, and in no event shall Company or its licensors be
              responsible for any content, products, or other materials on or
              available from such sites. Company provides the Service to you
              pursuant to the terms and conditions of this Agreement. You
              recognize, however, that certain third party providers of
              ancillary software, hardware, or services may require you to agree
              to additional or different license or other terms prior to your
              use of or access to such software, hardware, or service.
            </li>
            <li>
              <Typography variant="subtitle1">
                <span>6.</span> Disclaimers of Warranties.{" "}
              </Typography>
              <ul>
                <li>
                  <span>6.1.</span> DISCLAIMERS. COMPANY AND ITS LICENSORS
                  (INCLUDING LOOP BUILDER) MAKE NO REPRESENTATION, WARRANTY, OR
                  GUARANTY AS TO THE RELIABILITY, TIMELINESS, QUALITY,
                  SUITABILITY, TRUTH, AVAILABILITY, ACCURACY, OR COMPLETENESS OF
                  THE SERVICE OR ANY CONTENT. COMPANY AND ITS LICENSORS DO NOT
                  REPRESENT OR WARRANT THAT: (A) THE USE OF THE SERVICE WILL BE
                  SECURE, TIMELY, UNINTERRUPTED, OR ERROR-FREE OR OPERATE IN
                  COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM, OR
                  DATA; (B) THE SERVICE WILL MEET YOUR REQUIREMENTS OR
                  EXPECTATIONS; (C) ANY STORED DATA WILL BE ACCURATE OR
                  RELIABLE; (D) THE QUALITY OF ANY PRODUCTS, SERVICE,
                  INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU
                  THROUGH THE SERVICE WILL MEET YOUR REQUIREMENTS OR
                  EXPECTATIONS; (E) ERRORS OR DEFECTS WILL BE CORRECTED; OR (F)
                  THE SERVICE OR THE SERVER(S) THAT MAKE THE SERVICE AVAILABLE
                  ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE SERVICE
                  AND ALL CONTENT IS PROVIDED TO YOU STRICTLY ON AN "AS IS"
                  BASIS. ALL CONDITIONS, REPRESENTATIONS, AND WARRANTIES,
                  WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING,
                  WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY,
                  FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD
                  PARTY RIGHTS, ARE HEREBY DISCLAIMED TO THE MAXIMUM EXTENT
                  PERMITTED BY APPLICABLE LAW BY COMPANY AND ITS LICENSORS.
                </li>
                <li>
                  <span>6.2.</span> NO RESPONSIBILITY FOR THIRD PARTY FAILURES.
                  THE SERVICE MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER
                  PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC
                  COMMUNICATIONS. COMPANY IS NOT RESPONSIBLE FOR ANY DELAYS,
                  DELIVERY FAILURES, OR OTHER DAMAGE RESULTING FROM SUCH
                  PROBLEMS.
                </li>
                <li>
                  <span>6.3.</span> Survival. This{" "}
                  <span className={`${classes.underLine} ${classes.noPad}`}>
                    Section 6{" "}
                  </span>{" "}
                  &nbsp; shall survive expiration or termination of this
                  Agreement.
                </li>
                <li>
                  <span>6.4.</span> Limitation of Liability. COMPANY, ITS
                  AFFILIATES, EMPLOYEES, OFFICERS, REPRESENTATIVES, SERVICE
                  PROVIDERS AND ITS SUPPLIERS SHALL NOT BE RESPONSIBLE FOR, NOR
                  BE LIABLE FOR, ANY DIRECT, INDIRECT, INCIDENTAL,
                  CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE OR OTHER DAMAGES
                  (INCLUDING FOR LOSS OF PROFITS, GOODWILL, OR DATA), HOWEVER
                  CAUSED, ON ANY THEORY OF LIABILITY, INCLUDING CONTRACT OR TORT
                  (INCLUDING NEGLIGENCE OR PROFESSIONAL ERRORS AND OMISSIONS),
                  ARISING OUT OF OR RELATING IN ANY WAY TO (I) THE SERVICE, (II)
                  INFORMATION CONTAINED WITHIN THE SERVICE, (III) OTHER USERS OF
                  THE SERVICE, (IV) OPERATORS OF EXTERNAL SITES, OR (V) ANY
                  HOSTED CONTRACT. YOUR AND EACH USER'S SOLE AND EXCLUSIVE
                  REMEDY FOR ANY DISPUTE WITH COMPANY IS TO STOP USING THE
                  SERVICE. IF ANY PART OF THE FOREGOING LIMITATION OF LIABILITY
                  IS FOUND TO BE INVALID OR UNENFORCEABLE FOR ANY REASON, THEN
                  THE AGGREGATE LIABILITY OF COMPANY UNDER SUCH CIRCUMSTANCES,
                  FOR LIABILITIES THAT OTHERWISE WOULD HAVE BEEN LIMITED, SHALL
                  NOT EXCEED THE LOWEST LIMITATION OF LIABILITY ALLOWED BY
                  APPLICABLE LAW, OR, IN THE EVENT SUCH AMOUNT IS NOT PRESCRIBED
                  BY LAW, ONE HUNDRED DOLLARS ($100).
                </li>
              </ul>
            </li>
            <li>
              <span className={`${classes.padRight} ${classes.bold}`}>7.</span>
              Indemnification. You shall indemnify and hold harmless Company and
              its directors, officers, employees, corporate affiliates, and
              agents from and against any and all claims, allegations, demands,
              suits and proceedings brought or asserted by any third party
              (collectively, "Claims"), and from and against any liability,
              judgments, awards, damages, settlements, fees and costs (including
              attorney's fees) (collectively, "Damages") arising directly from
              such Claim, that results directly from: (i) any Claim alleging
              that use of any data or materials submitted to the Service by you
              or any Users infringes the rights of, or has caused harm to, a
              third party; (ii) any Claim in connection with or arising from a
              Hosted Contract; (iii) any Claim arising out of or relating to
              your unauthorized use, disclosure, transfer, or processing of any
              information or data submitted to or acquired from the Service by
              you or any Users; or (iv) any Claim arising from the breach by you
              or any Users of this Agreement, or any representation, warranty,
              or covenant hereof, or your or any Users' violation of applicable
              laws. This{" "}
              <span className={`${classes.underLine} ${classes.noPad}`}>
                Section 7{" "}
              </span>{" "}
              &nbsp; shall survive expiration or termination of this Agreement.
            </li>
            <li>
              <Typography variant="subtitle1">
                <span>8.</span> Miscellaneous.{" "}
              </Typography>
              <ul>
                <li>
                  <span>8.1.</span> Assignment. You may not assign this
                  agreement without the prior written approval of Company. This
                  Agreement (together with any license or rights granted to you
                  hereunder, if any) may be assigned by Company, without your
                  consent or notice to you, to any Substitute Provider. Any
                  purported assignment in violation of this section shall be
                  void.
                </li>
                <li>
                  <span>8.2.</span> Other Terms. This EULA shall be governed by
                  North Carolina law and controlling U.S. federal law, without
                  regard to the choice or conflicts of law provisions of any
                  jurisdiction, and any disputes, actions, claims, or causes of
                  action arising out of, or in connection with, any Service or
                  this EULA shall be subject to the exclusive jurisdiction of
                  the state and federal courts located within, or having
                  jurisdiction over, New Bern, NC, and the parties hereby
                  consent to the jurisdiction of such courts. This EULA, and the
                  Privacy Policy, constitutes a complete and exclusive statement
                  of the terms of the agreement between the parties with respect
                  to its subject matter. Except as provided herein, this EULA
                  may not be amended, supplemented, or otherwise modified except
                  by a written agreement executed by both parties. If any
                  provision of this EULA is held by a court of competent
                  jurisdiction to be invalid or unenforceable, then such
                  provision(s) shall be construed, as nearly as possible, to
                  reflect the intentions of the invalid or unenforceable
                  provision(s), with all other provisions remaining in full
                  force and effect. Any notice required or otherwise made under
                  this EULA shall be in writing, sent by overnight courier, or
                  registered or certified mail (a) to Company at 235 E Palmer
                  Drive, New Bern, NC, 28560, or at such other address as may be
                  designated pursuant to the foregoing; and (b) to you at the
                  address on record in your account information, or by means of
                  email to the email address on record in your account
                  information. Notice shall be deemed to have been given upon
                  the expiration of 48 hours after mailing or posting if sent by
                  courier or registered or certified mail, or in the case of
                  email notice, 12 hours after sending. No joint venture,
                  partnership, employment, or agency relationship exists between
                  you and Company as a result of this EULA or use of the
                  Service. The failure of Company to enforce any right or
                  provision in this EULA shall not constitute a waiver of such
                  right or provision unless acknowledged and agreed to by
                  Company in writing.
                </li>
              </ul>
            </li>
          </ul>
        </Box>
      </Box>
    </Container>
  );
}

export default EULAPolicy;
