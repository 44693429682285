import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import CardMedia from "@material-ui/core/CardMedia";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7764CA",
    },
    secondary: {
      light: "#0066ff",
      main: "#0044ff",
      contrastText: "#ffcc00",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "1px solid #AA9CE9",
        },
        "&:hover:not($disabled):before": {
          borderBottom: "2px solid #6C59B4",
        },
      },
    },
  },
  typography: {
    fontFamily: "Open Sans', sans-serif !important",
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: '40px',
    padding: theme.spacing(5, 5, 7, 5),
    marginTop: "40px",
  },
  logo: {
    width: "268px",
    cursor: "pointer",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  image: {
    backgroundImage: "url(/static/images/login-bg.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "0 30px 30px 0",
    boxShadow: "0 11px 30px rgba(0,0,0,.16)",
  },
  paper: {
    margin: theme.spacing(4, 10),
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    height: "calc(100% - 63px)",
  },
  radiusLeft: {
    borderRadius: "30px 0 0 30px",
    boxShadow: "0 11px 30px rgba(0,0,0,.16)",
  },
  sectionTitle: {
    fontSize: "14px",
    fontWeight: "600",
    marginTop: theme.spacing(3),
    color: "#707070",
  },
  form: {
    width: "100%",
  },
  signUpLink: {
    marginTop: "50px",
  },
  submit: {
    padding: theme.spacing(1, 4, 1),
    backgroundColor: "#E1CCBE",
    textTransform: "inherit",
    fontWeight: "normal",
    color: "#707070",
    fontFamily: "Open Sans', sans-serif !important",
    width: "auto",
    "&:hover": {
      backgroundColor: "#493E75",
    },
  },
  vCenter: {
    alignItems: "center",
    marginTop: theme.spacing(3),
  },
  footerBar: {
    position: "fixed",
    left: "0",
    bottom: "0",
    display: "flex",
    backgroundColor: "#fff",
    boxShadow: "0 -4px 20px rgba(108, 89, 180, .08)",
    padding: theme.spacing(1, 3),
    fontSize: "12px",
    width: "100%",
  },

  address: {
    color: "#5E5B70",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  copyrights: {
    color: "#5E5B70",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  social: {
    color: "#5E5B70",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  socialBtn: {
    color: "black",
    marginLeft: "10px",
  },
  transparentBg: {
    backgroundColor: "transparent",
  },
  smText: {
    fontSize: "14px",
    color: theme.palette.grey[500],
  },
  fontWeightMedium: {
    fontWeight: "600",
  },
  lockIcon: {
    fontSize: "56px",
    color: "#A2A0B0",
  },
  modalTitle: {
    fontSize: "18px",
    color: "#1A1A1A",
    fontWeight: "600",
  },
  smIcons: {
    color: "#A2A0B0",
  },
  rounded10: {
    borderRadius: "10px",
  },
  inputWrapper: {
    width: "calc(100% - 32px)",
    "& .MuiFormControl-root": {
      marginTop: theme.spacing(1),
    },
  },
  errorMsg: {
    marginTop: 0,
    color: "red",
    paddingLeft: theme.spacing(4),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[800],
  },
});

export default function SignupSuccess(props) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      {/* New Style */}
      <Container maxWidth="sm">
        <Paper>
          <Card className={classes.root}>
            <CardMedia
              className={classes.media}
              image="/static/images/npl-9.png"
              title="Nurse ProLink Logo"
            />
            <CardContent>
              <Typography
                variant="h5"
                component="p"
                style={{ textAlign: "center", color: "#333333" }}
              >
                Thanks for signing up!
              </Typography>
              <br />

              <p>
                Your account has been successfully created. Please check your
                email for further instructions.
              </p>
            </CardContent>
          </Card>
        </Paper>
      </Container>

      {/* Footer */}
      <Grid className={classes.footerBar}>
        <Grid item xs={6}>
          <Paper
            elevation={0}
            className={`${classes.address} ${classes.transparentBg}`}
          >
            <RoomOutlinedIcon fontSize="small" />
            Placement Loop, Inc. 712 Arendell Street Morehead City, NC 28557
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper
            elevation={0}
            className={`${classes.copyrights} ${classes.transparentBg}`}
          >
            Copyright © Placement Loop, Inc. 2024. All Rights Reserved.
          </Paper>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
