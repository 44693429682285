import React, { Fragment, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router";
import { userRoles } from "../../utils";

const useStyles = makeStyles((theme) => ({
  vCenter: {
    alignItems: "center",
    marginTop: theme.spacing(3),
  },
  textCenter: {
    textAlign: "center",
  },
  ml20: {
    marginLeft: "20px",
  },
  getStartedModal: {
    textAlign: "center",
    "& .MuiTypography-root": {},
  },
  modalTitle: {
    fontSize: "16px !important",
    fontWeight: 600,
  },
  modalTitleBlack: {
    color: "#253551",
    fontSize: "16px !important",
    fontWeight: 600,
    textAlign: "center",
  },
  smText: {
    fontSize: "12px",
    color: "#5E5B70",
  },
  textCenter: {
    textAlign: "center",
  },
  mt35: {
    marginTop: theme.spacing(4.5),
  },
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: "#6C59B4",
    padding: theme.spacing(1, 5),
    borderRadius: "20px",
    textTransform: "inherit",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: " #493E75",
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#7764CA",
    },
    secondary: {
      light: "#0066ff",
      main: "#0044ff",
      contrastText: "#ffcc00",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((styles) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const GetStartedDialog = ({ open, handleClose, ...props }) => {
  const classes = useStyles();
  const history = useHistory();
  const loggedIn = localStorage.getItem("userRole");

  if (loggedIn) {
    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={classes.getStartedModal}
        maxWidth={"md"}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></DialogTitle>
        <DialogContent style={{ color: "#fff" }}>
          <Typography className={classes.modalTitle}>
            We already found great Career Navigators to work on your order!
          </Typography>
          <Typography>
            Our system analyzed your order and Career Navigator profiles to
            identify who is best to work your order.
          </Typography>
          <Typography className={classes.mt35}>
            There's no commitment on either side - Career Navigators may express
            an interest in working your order and you can choose to accept or
            decline their interest.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={`${classes.button} ${classes.mt35}`}
            onClick={() =>
              loggedIn === userRoles.BUYER
                ? history.push("/Pages/Employer/Dashboard")
                : history.push("/Pages/Worker/PlacementOrder")
            }
          >
            Ok, I'm Ready to Get Started
          </Button>
        </DialogContent>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={classes.getStartedModal}
        maxWidth={"sm"}
        PaperProps={{
          style: {
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></DialogTitle>
        <DialogContent>
          <Typography className={classes.modalTitle}>Thank You!</Typography>
          <Typography className={classes.smText}>
            A Career Navigator will contact you shortly.
          </Typography>
          <Box
            mb={2}
            className={classes.textCenter}
            style={{ padding: "30px", paddingBottom: "0" }}
          >
            <Button
              variant="contained"
              color="primary"
              className={`${classes.button} ${classes.ml20}`}
              onClick={() => history.push("/")}
            >
              Go Back to Home Page
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
};

export default GetStartedDialog;
