import moment from "moment-timezone";

const timeZone = "America/New_York";
const timeFormat = "MMM DD, YYYY hh:mm A z";

// Convert Number type value to money
Number.prototype.toMoney1 = function (decimals, decimal_sep, thousands_sep) {
  let n = this;

  const c = isNaN(decimals) ? 2 : Math.abs(decimals);
  // if decimal is zero we must take it, it means user does not want to show any decimal

  const d = decimal_sep || ".";
  // if no decimal separator is passed we use the dot as default decimal separator (we MUST use a decimal separator)
  /*
    according to [https://stackoverflow.com/questions/411352/how-best-to-determine-if-an-argument-is-not-sent-to-the-javascript-function]
    the fastest way to check for not defined parameter is to use typeof value === 'undefined'
    rather than doing value === undefined.
    */

  const t = typeof thousands_sep === "undefined" ? "," : thousands_sep;
  // if you don't want to use a thousands separator you can pass empty string as thousands_sep value

  const sign = n < 0 ? "-" : "";

  // extracting the absolute value of the integer part of the number and converting to string

  const i = `${parseInt((n = Math.abs(n).toFixed(c)))}`;

  var j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    sign +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
};

export function _convertToMoney(price, addComma = true) {
  if (typeof price === "string") {
    price = parseFloat(price);
  }
  price = price || 0;
  if (addComma) {
    return price.toMoney1(2, ".", ",");
  }
  return price.toMoney1(2, ".", "");
}

// Convert String type value to money
String.prototype.toMoney = function () {
  const price = parseFloat(this) || 0;
  return `${price.toMoney1()}`;
};

export const _toMoney = (amount = 0, currency = "$") =>
  `${currency}${_convertToMoney(amount)}`;

export const _momentDate = (date = new Date()) => moment(date);

export const _momentFormat = (
  date = new Date(),
  format = "YYYY-MM-DD hh:mm a",
) => _momentDate(date).format(format);

export const _momentFormatEst = (date) =>
  moment(date).utc().tz(timeZone).format(timeFormat);

export const _stringCamalize = (str) =>
  ` ${str}`
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());

export const _formatPhoneNo = (number) => {
  const PhoneNumber = number.replace("1 ", "").match(/\d*/g).join("");
  return PhoneNumber.replace(/(\d{3})\-?(\d{3})\-?(\d{4}).*/, "1 $1-$2-$3");
};

export const formatNumber = (num) => {
  const numParts = num.toString().split(".");
  numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return numParts.join(".");
};

export const _setRemoveItem = (array, removed) =>
  array?.filter((it) => it.key !== removed.key);

export const emailIsValid = (email) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
};

// export const _formatHourlyRate = (rate) => {
//   const formattedRate = rate;

//   if (!formattedRate) {
//     return `$0.00/hr`;
//   } else {
//     return formattedRate._toMoney();
//   }
// };
