import axios from "axios";
import { config } from "../../config/config";
// import history from "../../customHistory";
import { logout } from "../../utils/authFunctions";

/**
 * Request Wrapper with default success/error actions
 */
const requestEventToken = async (options) => {
  const token = "aaPiuEjuSR9RpERwjwanQba1da1WIYaP";

  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (token) {
    headers = {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  }
  const client = axios.create({
    baseURL:
      "http://ploop--Publi-vw8gPqsUT26l-616703289.us-east-1.elb.amazonaws.com/stats/event",
    headers: {
      ...headers,
    },
  });

  const onSuccess = (response) => {
    console.info("Request Successful!", response.data);
    return response;
  };

  const onError = (error) => {
    console.error("API Error => ", error);
    if (error.response) {
      if (error.response.status === 440) {
        logout();
      }

      return Promise.reject(
        error.response.data
          ? error.response.data
          : error.response || error.message,
      );
    }
    if (error.request) {
      return Promise.reject(error.request);
    }

    return Promise.reject(error.message);
  };

  try {
    const response = await client(options);
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

export default requestEventToken;
