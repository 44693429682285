import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { contactTokenVerify } from "../../redux/services/users";
import { checkIsLogin, deCodeToken, logout } from "../../utils/authFunctions";
import { ToasterNotification } from "../../utils";
import AppSpinner from "../AppSpinner";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
  },
}));

function ContractVerify(props) {
  const history = useHistory();
  const classes = useStyles();
  const [toastOpen, setToastOpen] = useState({
    open: false,
    message: "",
    type: "",
  });
  const [showAppSpinner, setShowAppSpinner] = useState(false);

  useEffect(() => {
    verifyToken();
  }, []);
  const verifyToken = async () => {
    const parsed = queryString.parse(props?.location?.search);
    try {
      if (parsed?.contractToken) {
        setShowAppSpinner(true);
        const res = await contactTokenVerify(parsed?.contractToken);
        if (res?.statusCode === 200) {
          localStorage.setItem("contractToken", parsed?.contractToken);
          setShowAppSpinner(false);

          if (res?.data.userExists) {
            if (res?.data.isAccountVerified) {
              if (checkIsLogin()) {
                history.push("/Pages/Employer/contractpreview");
              } else {
                history.push("/login?redirect=/Pages/Employer/contractpreview");
              }
            } else {
              localStorage.setItem(
                "userDetails",
                JSON.stringify(res?.data?.userDetails),
              );
              history.push(
                `/auth/verify-registration?redirect=/Pages/Employer/contractpreview&contractToken=${parsed?.contractToken}`,
              );
            }
          } else {
            history.push("/login?redirect=/Pages/Employer/contractpreview");
          }
        } else {
          setShowAppSpinner(false);
        }
      }
    } catch (err) {
      setShowAppSpinner(false);
      console.error("Error in verifying contract token => ", err);
      setToastOpen({ open: true, message: err.message, type: "error" });
    }
  };
  const handleCloseToast = () => {
    setToastOpen({ open: false, message: "", type: "" });
    history.push("/");
  };
  return (
    <div className={classes.main}>
      {showAppSpinner && <AppSpinner />}
      {toastOpen.open && (
        <ToasterNotification
          open
          message={toastOpen.message}
          type={toastOpen.type}
          handleClose={handleCloseToast}
        />
      )}
    </div>
  );
}

export default ContractVerify;
