import React from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  loading: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
    background: "rgba(0, 0, 0, 0.18823529411764706)",
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-20px",
    marginLeft: "-20px",
  },
});

export default function () {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    </div>
  );
}
