import React from "react";
import { useHistory } from "react-router-dom";
import { finalizeUserSignup } from "../../redux/services/worker";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { makeStyles, createTheme, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { ThemeProvider } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7764CA",
    },
    secondary: {
      light: "#0066ff",
      main: "#0044ff",
      contrastText: "#ffcc00",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "1px solid #AA9CE9",
        },
        "&:hover:not($disabled):before": {
          borderBottom: "2px solid #6C59B4",
        },
      },
    },
  },
  typography: {
    fontFamily: "Open Sans', sans-serif !important",
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: '40px',
    padding: theme.spacing(5, 5, 7, 5),
    marginTop: "40px",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  submit: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1, 4, 1),
    backgroundColor: "#E1CCBE",
    textTransform: "inherit",
    fontWeight: "normal",
    color: "#707070",
    fontFamily: "Open Sans', sans-serif !important",
  },
  footerBar: {
    position: "fixed",
    left: "0",
    bottom: "0",
    display: "flex",
    backgroundColor: "#fff",
    boxShadow: "0 -4px 20px rgba(108, 89, 180, .08)",
    padding: theme.spacing(1, 3),
    fontSize: "12px",
    width: "100%",
  },
  address: {
    color: "#5E5B70",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  copyrights: {
    color: "#5E5B70",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

export default function ConfirmPassword(props) {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const signupToken = params.signupToken;
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  if (!signupToken) {
    history.push("/");
  }

  const url = window.location.href;
  console.info(url);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!password || !confirmPassword) {
      alert("Please enter password and confirm password");
      return;
    }

    if (password === confirmPassword) {
      const finalizeUserSignupResponse = await finalizeUserSignup(
        signupToken,
        password,
      );

      console.info(finalizeUserSignupResponse);

      if (finalizeUserSignupResponse?.status === 200) {
        console.info("User signed up successfully");
        history.push("/login");
      } else {
        console.error("Error in create order => ", finalizeUserSignupResponse);
        history.push("/");
      }
    } else {
      alert("Passwords do not match");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Paper>
          <Card className={classes.root}>
            {/* <CardHeader title="Nurse ProLink" subheader="" /> */}
            <CardMedia
              className={classes.media}
              image="/static/images/npl-9.png"
              title="Nurse ProLink Logo"
            />
            <CardContent>
              <Typography
                variant="h5"
                component="p"
                style={{ textAlign: "center", color: "#333333" }}
              >
                Thank you for signing up!
              </Typography>
              <Typography
                component="p"
                style={{ textAlign: "center", color: "#333333" }}
              >
                The last step is to set your password.
              </Typography>
              {/* <h3>Set your Password</h3> */}
              <br />
              <form onSubmit={handleSubmit}>
                <TextField
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => handlePasswordChange(e)}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Confirm Password"
                  type="password"
                  id="confirm_password"
                  value={confirmPassword}
                  onChange={(e) => handleConfirmPasswordChange(e)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Set Password and Continue
                </Button>
              </form>
            </CardContent>
          </Card>
        </Paper>
      </Container>

      {/* Footer */}
      <Grid className={classes.footerBar}>
        <Grid item xs={6}>
          <Paper
            elevation={0}
            className={`${classes.address} ${classes.transparentBg}`}
          >
            <RoomOutlinedIcon fontSize="small" />
            Placement Loop, Inc. 712 Arendell Street Morehead City, NC 28557
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper
            elevation={0}
            className={`${classes.copyrights} ${classes.transparentBg}`}
          >
            Copyright © Placement Loop, Inc. 2024. All Rights Reserved.
          </Paper>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
