import request from "./request";
import requestToken from "./requestToken";

export const createPlacementOrder = (placementOrder) => {
  return requestToken({
    url: "/users/placement_order",
    method: "POST",
    data: placementOrder,
  });
};

export const finalizeUserSignup = (signupToken, password) => {
  return request({
    url: "/users/signup/finalize",
    method: "POST",
    data: {
      token: signupToken,
      password: password,
    },
  });
};

export const createWorkerOrder = (data) =>
  requestToken({
    url: "/orders/sell",
    method: "POST",
    data,
  });

export const getPlacementOrder = (data) =>
  requestToken({
    url: `/users/placement_order?order_id=${data}`,
    method: "GET",
  });

export const getWorkerPoList = (queryString) =>
  requestToken({
    url: `/users/orders`,
    method: "GET",
  });

export const sendSubInvite = (data) =>
  requestToken({
    url: "/users/subscription-invite ",
    method: "POST",
    data,
  });

export const paymentConfig = (type) =>
  requestToken({
    url: `/payments/config?type=${type}`,
    method: "GET",
  });

export const verifyRegistrationCustom = (subscriptionToken) =>
  request({
    url: `/auth/verify-registration?subscriptionToken=${subscriptionToken}`,
    method: "GET",
  });

// Add the token here
export const getPlacementOrderOptions = () =>
  request({
    url: "/form_details/all",
    method: "GET",
  });

// add
// export const createPlacementOrder = (data) =>
//   requestToken({
//     url: "/orders/sell",
//     method: "POST",
//     data,
//   });
