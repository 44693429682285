import { Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import { getPlacementOrderOptions } from "../../redux/services/worker";
import { ToasterNotification } from "../../utils";
import AppSpinner from "../AppSpinner";
import GetStartedDialog from "../common/getSTartedDialog";
import OrdersList from "./workerOrderList";
import {
  emailIsValid,
  _formatPhoneNo,
  _toMoney,
} from "../../utils/commonUtils/globalFunc";
import Select from "react-select";
import {
  NumberFormatBonus,
  NumberFormatHourlyRate,
} from "../common/numberFormat";
import { createPlacementOrder } from "../../redux/services/worker";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { v4 as uuidv4 } from "uuid";
import {
  writePageViewEvent,
  writeButtonClickEvent,
} from "../../redux/services/events";

// #FFFFFF
// #EEEDEB
// #E1CCBE
// #757263
// #000000

const theme = createTheme({
  palette: {
    primary: {
      main: "#E1CCBE",
    },
    secondary: {
      light: "#E1CCBE",
      main: "#D1CCBD",
      contrastText: "#ffcc00",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const useStyles = makeStyles((theme) => ({
  ".optionContainer li:hover": {
    background: "red",
    color: "#fff",
  },
  ".optionContainer .highlight": {
    background: "red",
    color: "#fff",
  },
  formWrapper: {
    backgroundColor: "#fff",
    borderRadius: "0 10px 10px 10px",
    boxShadow: "0 3px 6px rgba(0,0,0,.16)",
    padding: theme.spacing(3.5),
    position: "relative",
    width: "1150px",
    margin: "0 auto",
    "&:after": {
      content: '""',
      width: "2px",
      height: "48px",
      backgroundColor: "#6C59B4",
      position: "absolute",
      left: "0",
      top: "0",
    },
  },
  wrapper: {
    width: "100%",
    height: "auto",
    position: "relative",
    paddingRight: theme.spacing(3.5),
  },
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: "#E1CCBE",
    padding: theme.spacing(1, 5),
    borderRadius: "20px",
    color: "#111",
    textTransform: "inherit",
  },
  backButton: {
    marginRight: theme.spacing(1),
    textTransform: "inherit",
    color: "#6C59B4",
  },
  completed: {
    display: "inline-block",
    backgroundColor: "#6C59B4",
    padding: theme.spacing(0.5, 2),
    borderRadius: "20px",
    color: "#fff",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& .MuiFormControl-marginNormal": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.5),
    },
  },
  recruitingOrderEmpty: {
    backgroundImage: "url(/static/images/empty-box.svg)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "350px",
    height: "100%",
    width: "100%",
  },
  divider: {
    borderLeft: "dashed 1px #A2A0B0",
  },
  sTitle: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#5E5B70",
    borderBottom: "solid 1px #C7C7C9",
    paddingBottom: theme.spacing(1),
  },
  spacingX: {
    padding: theme.spacing(0, 3.5),
  },
  mb20: {
    marginBottom: theme.spacing(2.5),
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
  },
  stepCount: {
    fontWeight: "600",
    color: "#5E5B70",
  },
  select: {
    marginTop: theme.spacing(1),
  },
  selectLabel: {
    fontWeight: "600",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
    color: "#5E5B70",
  },
  centerText: {
    textAlign: "center",
  },
  textArea: {
    backgroundColor: "#F9F9F9",
    "&:hover": {
      backgroundColor: "#F9F9F9",
    },
  },
  multiselectContainer: {
    border: "solid 1px red",
  },
  inlineWrapper: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1.25),
    borderBottom: "solid 1px #C7C7C9",
    marginBottom: theme.spacing(3),
  },
  orderId: {
    fontSize: "18px !important",
    fontWeight: 600,
    color: "#5E5B70",
  },
  totalMatch: {
    color: "#5E5B70",
  },
  matchesRow: {
    display: "flex",
    borderBottom: "solid 1px #C7C7C9",
    marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  accordionMain: {
    "&.MuiAccordion-root": {
      boxShadow: "none",
      borderBottom: "solid 1px #C7C7C9",
      borderRadius: 0,
    },
    "& .MuiAccordionSummary-root": {
      padding: 0,
    },
  },
  accordion: {
    "&.MuiButtonBase-root": {
      alignItems: "flex-start",
    },
  },
  heading: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  avatar: {
    height: "28px",
    width: "28px",
    borderRadius: "50%",
    backgroundColor: "#E1CCBE",
    marginRight: theme.spacing(2.5),
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    fontSize: "12px",
    fontWeight: 600,
    flexShrink: 0,
  },
  userName: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#6C59B4",
    marginBottom: theme.spacing(1.25),
    width: "100%",
  },
  userDetails: {
    fontSize: "14px",
    color: "#5E5B70",
    "& span": {
      marginRight: theme.spacing(2.5),
      marginBottom: theme.spacing(1.25),
      display: "inline-flex",
      "& strong": {
        fontWeight: 600,
      },
    },
  },
  accordionDetails: {
    "&.MuiAccordionDetails-root": {
      backgroundColor: "#f9f9f9",
      flexWrap: "wrap",
    },
    "& .MuiTypography-root": {
      color: "#333333",
      width: "100%",
      "& strong": {
        fontWeight: 600,
      },
    },

    "& form": {
      width: "100%",
    },
  },
  captchaAlign: {
    margin: "10px auto",
  },
  scrolling: {
    paddingRight: theme.spacing(1.2),
  },
  getStartedModal: {
    textAlign: "center",
    "& .MuiTypography-root": {
      color: "#fff",
    },
  },
  modalTitle: {
    color: "#fff",
    fontSize: "16px !important",
    fontWeight: 600,
  },
  modalTitleBlack: {
    color: "#253551",
    fontSize: "16px !important",
    fontWeight: 600,
    textAlign: "center",
  },
  smText: {
    fontSize: "12px",
    color: "#5E5B70",
  },
  textCenter: {
    textAlign: "center",
  },
  mt35: {
    marginTop: theme.spacing(4.5),
  },
  cnModal: {
    "& closeButton": {
      color: "#000",
    },
  },
  flexBox: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: theme.spacing(2.5),
  },
  hrsWrapper: {
    display: "inline-flex",
    marginBottom: theme.spacing(1.25),
    width: "48%",
    "&:nth-child(even)": {
      marginLeft: theme.spacing(2.5),
    },
    "& span": {
      fontSize: "13px",
      color: "#5E5B70",
      "&:first-of-type": {
        marginRight: theme.spacing(1.5),
        width: "200px",
      },

      "& input": {
        border: 0,
        borderBottom: "solid 1px #E1CCBE",
        width: "41px",
        color: "#FF0000",
        "&:hover, &:focus": {
          outline: "none",
        },
      },
    },
  },
  headerWrapper: {
    display: "flex",
    marginBottom: theme.spacing(1.25),
    width: "100%",
  },
  inputHeader: {
    fontSize: "13px !important",
    fontWeight: 600,
    flex: 1,
    "&:nth-child(1)": {
      flex: 2,
      maxWidth: "170px",
    },
    "&:nth-child(2)": {
      marginLeft: theme.spacing(1),
    },
  },
  nodataclass: {
    textAlign: "center",
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1.25),
    width: "100%",
  },
  categoryName: {
    fontSize: "13px",
    flex: 2,
    maxWidth: "170px",
  },
  inputInline: {
    flex: 1,
    maxWidth: "85px",
    "&:nth-child(2)": {
      marginLeft: theme.spacing(1),
    },
    "& input": {
      border: 0,
      borderBottom: "solid 1px #E1CCBE",
      width: "80%",
      color: "#5E5B70",
      "&:hover, &:focus": {
        outline: "none",
      },
    },
  },
  selectPlaceholder: {
    "& .MuiSelect-selectMenu": {
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "13px",
      "&:focus": {
        backgroundColor: "transparent",
      },
    },
  },
  fwSelect: {
    color: "#A2A0B0",
    "& .MuiSelect-selectMenu": {
      color: "#212121",
      "&:focus": {
        backgroundColor: "transparent",
      },
    },
  },
  inlineTitle: {
    display: "flex",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
    "& input": {
      border: 0,
      width: "60px",
      color: "#5E5B70",
      marginLeft: theme.spacing(2.5),
      "&:hover, &:focus": {
        outline: "none",
      },
    },
    "& .MuiTypography-root": {
      fontWeight: "600",
      color: "#5E5B70",
    },
  },
}));

function StepsWorker(props) {
  const usage = uuidv4();
  localStorage.setItem("DataKey", usage);

  useEffect(() => {
    writePageViewEvent(
      "Worker Placement Order",
      localStorage.getItem("DataKey"),
    );
  }, []);

  const classes = useStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = React.useState(1);
  const [employchecked, setEmploychecked] = React.useState([]);
  const [toastOpen, setToastOpen] = React.useState({
    open: false,
    message: "",
    type: "",
  });
  const [buttondisable, setButtonDisable] = React.useState(false);
  const [prevstate, setPrevstate] = React.useState(0);
  const [showAppSpinner, setShowAppSpinner] = React.useState(false);
  const [ordSubmit, setOrdSubmit] = React.useState(false);
  const [ordResponse, setOrdResponse] = React.useState({});
  const [hideButton, setHideButton] = useState(false);

  // Nursing Order state
  const [nurseLocationZipcode, setNurseLocationZipcode] = React.useState("");
  const [nursePositionValue, setNursePositionValue] = React.useState([]);
  const [nursePositionSingleValue, setNursePositionSingleValue] =
    React.useState(null);
  const [nurseSharedPosition, setNurseSharedPosition] = React.useState([]);
  // const [nurseLocationValue, setNurseLocationValue] = React.useState([]);
  const [nurseEducationValue, setNurseEducationValue] = React.useState([]);
  const [nurseEducationSingleValue, setNurseEducationSingleValue] =
    React.useState(null);
  const [nurseDegreeValue, setNurseDegreeValue] = React.useState([]);
  const [nurseCertificationValue, setNurseCertificationValue] = React.useState(
    [],
  );
  const [nurseExperienceValue, setNurseExperienceValue] = React.useState([]);
  const [nurseLicensureValue, setNurseLicensureValue] = React.useState([]);
  const [nurseAreaOfFocusValue, setNurseAreaOfFocusValue] = React.useState([]);
  const [nurseExpectedGraduationValue, setNurseExpectedGraduationValue] =
    React.useState("");
  const [nurseBenefitsValue, setNurseBenefitsValue] = React.useState([]);
  const [nurseTimingPreferenceValue, setNurseTimingPreferenceValue] =
    React.useState([]);
  const [
    nurseTimingPreferenceSingleValue,
    setNurseTimingPreferenceSingleValue,
  ] = React.useState(null);
  const [nurseSchedulePreferenceValue, setNurseSchedulePreferenceValue] =
    React.useState([]);
  const [nurseShiftPreferenceValue, setNurseShiftPreferenceValue] =
    React.useState([]);
  const [nurseEMRExperienceValue, setNurseEMRExperienceValue] = React.useState(
    [],
  );
  const [nurseMinHourlyRate, setNurseMinHourlyRate] = React.useState("");
  const [nurseMinBonus, setNurseMinBonus] = React.useState("");
  const [
    nurseNumberOfExperienceComponents,
    setNurseNumberOfExperienceComponents,
  ] = React.useState(1);
  const [nursePairedExperiencePosition, setNursePairedExperiencePosition] =
    React.useState([]);
  const [nursePairedExperienceDuration, setNursePairedExperienceDuration] =
    React.useState([]);
  // const [nursePositionExperiencePair, setNursePositionExperiencePair] =
  //   useState([{}]);
  // const [nurseJoinedExperience, setNurseJoinedExperience] = useState([[]]);
  // experience
  const [r, setR] = useState([]);
  const [e, setE] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [optionData, setOptionData] = useState([]);

  // Nursing Order Options
  const [nursePosition, setNursePosition] = React.useState([]);
  const [nurseExperience, setNurseExperience] = React.useState([]);
  const [nurseAreaOfFocus, setNurseAreaOfFocus] = React.useState([]);
  const [nurseBenefits, setNurseBenefits] = React.useState([]);
  const [nurseSchedulePreference, setNurseSchedulePreference] = React.useState(
    [],
  );
  const [nurseShiftPreference, setNurseShiftPreference] = React.useState([]);
  const [nurseTimingPreference, setNurseTimingPreference] = React.useState([]);
  const [nurseLocationState, setNurseLocationState] = React.useState([]);
  const [nurseCertification, setNurseCertification] = React.useState([]);
  const [nurseDegree, setNurseDegree] = React.useState([]);
  const [nurseEMRExperience, setNurseEMRExperience] = React.useState([]);
  const [nurseEducation, setNurseEducation] = React.useState([]);

  // Profile Info State
  const [nurseFirstName, setNurseFirstName] = React.useState("");
  const [nurseLastName, setNurseLastName] = React.useState("");
  const [nursePhoneNumber, setNursePhoneNumber] = React.useState("");
  const [nurseEmail, setNurseEmail] = React.useState("");
  const [zipCodeError, setZipCodeError] = React.useState(false);
  const [nurseAgreeToContact, setNurseAgreeToContact] = React.useState(false);

  // Wizard Enum
  const WizardEnum = {
    WorkerProfilePage: 1,
    WorkerPositionPage: 2,
    WorkerCertificationPage: 3,
    WorkerFinalizePage: 4,
  };

  const wizardPageOrder = [
    WizardEnum.WorkerProfilePage,
    WizardEnum.WorkerPositionPage,
    WizardEnum.WorkerCertificationPage,
    WizardEnum.WorkerDesiredDetailsPage,
  ];

  useEffect(() => {
    // call the backend to get the details for the form options
    getPlacementOrderOptions().then((res) => {
      setNursePosition(res?.nurse_position);
      setNurseSharedPosition(res?.nurse_position);
      setNurseExperience(res?.nurse_experience);
      setNurseAreaOfFocus(res?.nurse_area_of_focus);
      setNurseBenefits(res?.nurse_benefits);
      setNurseTimingPreference(res?.nurse_timing_preference);
      setNurseSchedulePreference(res?.nurse_schedule_preference);
      setNurseShiftPreference(res?.nurse_shift_preference);
      setNurseLocationState(res?.nurse_state);
      setNurseCertification(res?.nurse_certification);
      setNurseDegree(res?.nurse_degree);
      setNurseEMRExperience(res?.nurse_emr_experience);
      setNurseEducation(res?.nurse_education);
    });

    const firstNameFromLocalStore = localStorage.getItem("firstname");
    const lastNameFromLocalStore = localStorage.getItem("lastname");
    const emailFromLocalStore = localStorage.getItem("email");
    const mobileFromLocalStore = localStorage.getItem("mobileNumber");
    if (
      firstNameFromLocalStore &&
      lastNameFromLocalStore &&
      emailFromLocalStore &&
      mobileFromLocalStore
    ) {
      setNurseFirstName(firstNameFromLocalStore);
      setNurseLastName(lastNameFromLocalStore);
      setNursePhoneNumber(mobileFromLocalStore);
      setNurseEmail(emailFromLocalStore);
    }
  }, []);

  // factory method for creating a multi select
  function MakeSingleSelectInput(props) {
    const { options, selectedValues, changeHandler, placeholder } = props;

    const computedOptions = options.map((option) => {
      return {
        key: option.id,
        label: option.name,
        value: option.name,
      };
    });

    const onChange = (newValue, actionType) => {
      // console.info(newValue);
      changeHandler(newValue);
    };

    return (
      <Select
        isClearable
        closeMenuOnSelect={true}
        defaultValue={selectedValues}
        isMulti={false}
        name={placeholder}
        options={computedOptions}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={onChange}
      />
    );
  }

  // factory method for creating a multi select
  function MakeMultiSelectInput(props) {
    const { options, selectedValues, changeHandler, placeholder } = props;

    const computedOptions = options.map((option) => {
      return {
        key: option.id,
        label: option.name,
        value: option.name,
      };
    });

    const onChange = (newValue, actionType) => {
      changeHandler(newValue);
    };

    return (
      <Select
        isClearable
        closeMenuOnSelect={false}
        defaultValue={selectedValues}
        isMulti
        name={placeholder}
        options={computedOptions}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={onChange}
      />
    );
  }

  function MakePairedMultiSelectInput(props) {
    const {
      optionsPrimary,
      selectedValuesPrimary,
      placeholderPrimary,
      optionsSecondary,
      selectedValuesSecondary,
      placeholderSecondary,
      number,
      changeHandlerPrimary,
      changeHandlerSecondary,
    } = props;

    // Remove the "Still Looking", id: 0
    const filteredPrimaryOptions = optionsPrimary.filter(
      (option) => option.id !== 0,
    );

    // Convert the db representation to the react-select representation
    const computedOptionsPrimary = filteredPrimaryOptions.map((option) => {
      return {
        key: option.id,
        label: option.name,
        value: option.name,
      };
    });

    // Conver the db representation to the react-select representation
    const computedOptionsSecondary = optionsSecondary.map((option) => {
      return {
        key: option.id,
        label: option.name,
        value: option.name,
      };
    });

    const onChangePrimary = (newValue, actionType) => {
      const updatedR = [...nursePairedExperiencePosition];
      updatedR[number] = newValue;
      changeHandlerPrimary(updatedR);
    };

    const onChangeSecondary = (newValue, actionType) => {
      const updatedE = [...nursePairedExperienceDuration];
      updatedE[number] = newValue;
      changeHandlerSecondary(updatedE);
    };

    return (
      <>
        <label>
          <Select
            closeMenuOnSelect={true}
            defaultValue={selectedValuesPrimary[number]}
            name={placeholderPrimary}
            options={computedOptionsPrimary}
            placeholder="Select a role"
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={onChangePrimary}
          />
        </label>
        <Select
          closeMenuOnSelect={true}
          defaultValue={selectedValuesSecondary[number]}
          name={placeholderSecondary}
          options={computedOptionsSecondary}
          placeholder="Select a duration"
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={onChangeSecondary}
        />
      </>
    );
  }

  function handleZipCodeChange(event) {
    const { value } = event.target;

    const zipCodeRegex = /^\d{5}(-\d{4})?$/;

    setNurseLocationZipcode(value);

    if (zipCodeRegex.test(value)) {
      setZipCodeError(false);
    } else {
      setZipCodeError(true);
    }
  }

  //////////////////////////////////////////////
  // STEPS
  //////////////////////////////////////////////

  function handleAddAnotherExperienceEntry() {
    const newIndex = nurseNumberOfExperienceComponents + 1;
    setNurseNumberOfExperienceComponents(newIndex);
  }

  function makeProfilePageContent(headerBlock) {
    return (
      <div>
        {headerBlock}

        <Typography className={classes.selectLabel}>
          My Profile Details
        </Typography>
        <form>
          <FormControl className={classes.formControl} fullWidth>
            <TextField
              margin="normal"
              required
              fullWidth
              id="nurseFirstNameId"
              placeholder="First Name"
              name="representativeName"
              value={nurseFirstName}
              onChange={(e) => setNurseFirstName(e.target.value)}
              autoFocus
              className=""
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="nurseLastNameId"
              placeholder="Last Name"
              name="representativeName"
              value={nurseLastName}
              onChange={(e) => setNurseLastName(e.target.value)}
              autoFocus
              className=""
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="representativeName"
              placeholder="Mobile Number"
              name="representativeName"
              value={_formatPhoneNo(nursePhoneNumber)}
              onChange={(e) => setNursePhoneNumber(e.target.value)}
              autoFocus
              className="bgSeparation"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="representativeName"
              placeholder="Email"
              name="representativeName"
              value={nurseEmail}
              onChange={(e) => setNurseEmail(e.target.value)}
              autoFocus
              className="bgSeparation"
            />
            <Typography className={classes.selectLabel}>
              Current Position
            </Typography>

            <MakeSingleSelectInput
              options={nursePosition}
              selectedValues={nursePositionSingleValue}
              placeholder="Select one or more positions"
              changeHandler={setNursePositionSingleValue}
            />
          </FormControl>
        </form>
      </div>
    );
  }

  function makePositionPageContent(headerBlock) {
    return (
      <div>
        {headerBlock}
        <Typography className={classes.selectLabel}>
          This is what I am looking for
        </Typography>

        <form>
          <FormControl className={classes.formControl} fullWidth>
            {/* <Typography className={classes.selectLabel}>
              Location - Zip Code
            </Typography> */}
            {/* NB: Add a radius */}
            <TextField
              margin="normal"
              required
              fullWidth
              style={zipCodeError ? { borderBottom: "1px solid red" } : {}}
              id="zipcodeTextField"
              placeholder="Enter a zipcode"
              name="zipcodeTextField"
              value={nurseLocationZipcode}
              onChange={(e) => handleZipCodeChange(e)}
              autoFocus
              className="bgSeparation"
            />

            <Typography className={classes.selectLabel}>
              Area of Focus
            </Typography>
            <MakeMultiSelectInput
              options={nurseAreaOfFocus}
              selectedValues={nurseAreaOfFocusValue}
              placeholder="Area of Focus"
              changeHandler={setNurseAreaOfFocusValue}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="hourlyRateTextField"
              placeholder="Minimum Hourly Wage"
              name="hourlyRateTextField"
              value={nurseMinHourlyRate}
              onChange={(e) => setNurseMinHourlyRate(e.target.value)}
              autoFocus
              InputProps={{
                inputComponent: NumberFormatHourlyRate,
              }}
              className="bgSeparation"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="minBonusTextField"
              placeholder="Minumum Bonus (Optional)"
              name="minBonusTextField"
              value={nurseMinBonus}
              onChange={(e) => setNurseMinBonus(e.target.value)}
              autoFocus
              InputProps={{
                inputComponent: NumberFormatBonus,
              }}
              className="bgSeparation"
            />

            <Typography className={classes.selectLabel}>Benefits</Typography>
            <MakeMultiSelectInput
              options={nurseBenefits}
              selectedValues={nurseBenefitsValue}
              placeholder="Select one or more positions"
              changeHandler={setNurseBenefitsValue}
            />

            <Typography className={classes.selectLabel}>
              Timing Preference
            </Typography>
            <MakeSingleSelectInput
              options={nurseTimingPreference}
              selectedValues={nurseTimingPreferenceSingleValue}
              placeholder="Select one or more positions"
              changeHandler={setNurseTimingPreferenceSingleValue}
            />

            <Typography className={classes.selectLabel}>
              Schedule Preference
            </Typography>
            <MakeMultiSelectInput
              options={nurseSchedulePreference}
              selectedValues={nurseSchedulePreferenceValue}
              placeholder="Select one or more"
              changeHandler={setNurseSchedulePreferenceValue}
            />

            <Typography className={classes.selectLabel}>
              Shift Preference
            </Typography>
            <MakeMultiSelectInput
              options={nurseShiftPreference}
              selectedValues={nurseShiftPreferenceValue}
              placeholder="Select one or more"
              changeHandler={setNurseShiftPreferenceValue}
            />
          </FormControl>
        </form>
      </div>
    );
  }

  function makeCertificationPageContent(headerBlock) {
    return (
      <div>
        {headerBlock}
        <Typography className={classes.selectLabel}>
          This is what I have to offer
        </Typography>

        <form>
          <FormControl className={classes.formControl} fullWidth>
            <Typography className={classes.selectLabel}>Licensure</Typography>
            <MakeMultiSelectInput
              options={nurseLocationState}
              selectedValues={nurseLicensureValue}
              placeholder="Select one or more states"
              changeHandler={setNurseLicensureValue}
            />

            {/* This is no longer a multiselect */}
            <Typography className={classes.selectLabel}>
              Highest Completed Education
            </Typography>
            <MakeSingleSelectInput
              options={nurseEducation}
              selectedValues={nurseEducationSingleValue}
              placeholder="Select highest level of completed education"
              changeHandler={setNurseEducationSingleValue}
            />

            <Typography className={classes.selectLabel}>Degree</Typography>
            <MakeMultiSelectInput
              options={nurseDegree}
              selectedValues={nurseDegreeValue}
              placeholder="Select one or more degrees"
              changeHandler={setNurseDegreeValue}
            />

            <Typography className={classes.selectLabel}>
              Certification
            </Typography>
            <MakeMultiSelectInput
              options={nurseCertification}
              selectedValues={nurseCertificationValue}
              placeholder="Select one or more certifications"
              changeHandler={setNurseCertificationValue}
            />

            <Typography className={classes.selectLabel}>Experience</Typography>
            <span id="experienceContainer" className="multiselectContainer">
              {[...Array(nurseNumberOfExperienceComponents)].map((_, i) => (
                <MakePairedMultiSelectInput
                  key={i}
                  number={i}
                  optionsPrimary={nurseSharedPosition}
                  selectedValuesPrimary={nursePairedExperiencePosition}
                  placeholderPrimary="Select a position."
                  changeHandlerPrimary={setNursePairedExperiencePosition}
                  optionsSecondary={nurseExperience}
                  selectedValuesSecondary={nursePairedExperienceDuration}
                  placeholderSecondary="Select a duration"
                  changeHandlerSecondary={setNursePairedExperienceDuration}
                />
              ))}
            </span>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddAnotherExperienceEntry}
              className={classes.button}
            >
              Add more experience...
            </Button>

            <Typography className={classes.selectLabel}>
              EMR Experience
            </Typography>
            <MakeMultiSelectInput
              options={nurseEMRExperience}
              selectedValues={nurseEMRExperienceValue}
              placeholder="EMR Experience"
              changeHandler={setNurseEMRExperienceValue}
            />
          </FormControl>
        </form>
      </div>
    );
  }

  const matchList = [
    { id: 1, tease: "Match 1" },
    { id: 2, tease: "Match 2" },
  ];

  function makeFinalizationPageContent(headerBlock) {
    return (
      <div>
        {headerBlock}
        <form>
          <FormControl className={classes.formControl} fullWidth>
            <div>
              <div className={classes.inlineWrapper}>
                <Typography className={classes.orderId}>
                  Order Ready to Submit
                </Typography>
              </div>

              <Typography className={classes.selectLabel}>
                Based on the details you have entered, we have found the
                following matches for you.
              </Typography>

              <Typography className={classes.selectLabel}>
                Teasing match list:
                {matchList.map((match) => {
                  return (
                    <div key={match.id}>
                      <Typography>{match.tease}</Typography>
                    </div>
                  );
                })}
              </Typography>

              <Typography className={classes.selectLabel}>
                It's crucial to ensure your details are accurate. Please review
                the information on the right. If you need to make changes, click
                the 'Back' button above and update your entry. Once everything
                is correct, click the 'Submit' button below. By proceeding, you
                agree to the Nurse ProLink privacy policy, terms of use, and
                Omnibus EULA. <br />
                <a href="https://www.nurseprolink.com/privacy-policy">
                  https://www.nurseprolink.com/privacy-policy
                </a>
              </Typography>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={nurseAgreeToContact}
                    onChange={(event) =>
                      setNurseAgreeToContact(event.target.checked)
                    }
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label="I agree!"
              />
            </div>
          </FormControl>
        </form>
      </div>
    );
  }

  function getStepContent(step) {
    const headerBlock = (
      <Typography>
        <span className={classes.stepCount}>Step {step}</span> of 3
      </Typography>
    );

    switch (step) {
      // Worker Profile
      case WizardEnum.WorkerProfilePage:
        return makeProfilePageContent(headerBlock);

      // Worker Position
      case WizardEnum.WorkerPositionPage:
        return makePositionPageContent(headerBlock);

      // Worker Certification
      case WizardEnum.WorkerCertificationPage:
        return makeCertificationPageContent(headerBlock);

      // did they use the default case as the last step?
      default:
        return makeFinalizationPageContent(handleBack);
    }
  }

  const handleNext = () => {
    let isValid = false;

    const details = {
      page: "Worker Placement Order",
      action: "Next Button",
      activeStep: activeStep,
    };

    writeButtonClickEvent(details, localStorage.getItem("DataKey"));

    if (activeStep == WizardEnum.WorkerProfilePage) {
      if (isProfilePageValid(isValid)) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        isValid = true;
        return;
      }
    } else if (activeStep == WizardEnum.WorkerPositionPage) {
      if (isPositionPageValid(isValid)) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        isValid = true;
        return;
      }
    } else if (activeStep == WizardEnum.WorkerCertificationPage) {
      if (isCertificationPageValid(isValid)) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        isValid = true;
        return;
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      isValid = true;
    }

    if (isValid) {
      return;
    }
  };

  const isProfilePageValid = (isValid) => {
    if (!nurseFirstName || !nurseLastName) {
      setToastOpen({
        open: true,
        message: "Please define your first and last name",
        type: "warning",
      });
      return false;
    }

    if (!nursePhoneNumber) {
      setToastOpen({
        open: true,
        message: "Please add a contact number",
        type: "warning",
      });
      return false;
    }

    if (!emailIsValid(nurseEmail)) {
      setToastOpen({
        open: true,
        message: "Please define an email address",
        type: "warning",
      });
      return false;
    }

    if (!nursePositionSingleValue) {
      setToastOpen({
        open: true,
        message: "Please choose your current position",
        type: "warning",
      });
      return false;
    }

    return true;
  };

  const isPositionPageValid = (isValid) => {
    if (!nurseLocationZipcode) {
      setToastOpen({
        open: true,
        message: "Please choose your current Zip Code.",
        type: "warning",
      });
      return false;
    }

    if (!nurseAreaOfFocusValue.length > 0) {
      setToastOpen({
        open: true,
        message: "Please choose at least one Area of Focus.",
        type: "warning",
      });
      return false;
    }

    if (!nurseMinHourlyRate) {
      setToastOpen({
        open: true,
        message: "Please choose a minimum hourly wage.",
        type: "warning",
      });
      return false;
    }

    if (!nurseBenefitsValue.length > 0) {
      setToastOpen({
        open: true,
        message: "Please choose at least one Benefit you require.",
        type: "warning",
      });
      return false;
    }

    if (!nurseTimingPreferenceSingleValue) {
      setToastOpen({
        open: true,
        message: "Please choose when you are available.",
        type: "warning",
      });
      return false;
    }

    if (!nurseSchedulePreferenceValue.length > 0) {
      setToastOpen({
        open: true,
        message: "Please choose which schedule you prefer.",
        type: "warning",
      });
      return false;
    }

    if (!nurseShiftPreferenceValue.length > 0) {
      setToastOpen({
        open: true,
        message: "Please choose which shift or shifts you prefer.",
        type: "warning",
      });
      return false;
    }

    return true;
  };

  const isCertificationPageValid = (isValid) => {
    // nurseLicensureValue
    if (!nurseLicensureValue.length > 0) {
      setToastOpen({
        open: true,
        message: "Please select your licensure.",
        type: "warning",
      });
      return false;
    }

    // nurseEducationSingleValue
    if (!nurseEducationSingleValue) {
      setToastOpen({
        open: true,
        message: "Please choose your highest education completed.",
        type: "warning",
      });
      return false;
    }

    // nurseDegreeValue
    if (!nurseDegreeValue.length > 0) {
      setToastOpen({
        open: true,
        message: "Please choose your degree.",
        type: "warning",
      });
      return false;
    }

    // nurseCertificationValue
    if (!nurseCertificationValue.length > 0) {
      setToastOpen({
        open: true,
        message: "Please choose any certifications you hold.",
        type: "warning",
      });
      return false;
    }

    if (!nursePairedExperiencePosition.length > 0) {
      setToastOpen({
        open: true,
        message: "Please add some experience.",
        type: "warning",
      });
      return false;
    }

    const allJobRoles = nursePairedExperiencePosition.map((v) => v.key);

    function checkForDuplicates(array) {
      return new Set(array).size !== array.length;
    }

    if (checkForDuplicates(allJobRoles)) {
      console.info("Duplicates found");
      return false;
    }

    return true;
  };

  const handleBack = () => {
    if (activeStep == 1) {
      setActiveStep(1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  // const handleStep = (step) => () => {
  //   setActiveStep(step);
  // };

  const [lastopen, setLastopen] = React.useState(false);

  const handleClickLastOpen = () => {
    //history.push("/Pages/Worker/PlacementOrder");
  };

  const handleLastClose = () => {
    setLastopen(false);
  };

  const handleComplete = () => {
    // const newCompleted = new Set(completed);
    // newCompleted.add(activeStep);
    // setCompleted(newCompleted);
    // if (completed.size !== totalSteps() - skippedSteps()) {
    //   if (!ordSubmit) fetchData();
    //   else {
    //     setToastOpen({
    //       open: true,
    //       message: "Placement Order Already saved.",
    //       type: "warning",
    //     });
    //   }
    // } else {
    // }
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  //   setCompleted(new Set());
  //   setSkipped(new Set());
  // };

  // const isStepSkipped = (step) => skipped.has(step);

  // function isStepComplete(step) {
  //   return completed.has(step);
  // }

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[100],
    },
  });

  // const DialogTitle = withStyles(styles)((props) => {
  //   const { children, classes, onClose, ...other } = props;
  //   return (
  //     <MuiDialogTitle disableTypography className={classes.root} {...other}>
  //       <Typography variant="h6">{children}</Typography>
  //       {onClose ? (
  //         <IconButton
  //           aria-label="close"
  //           className={classes.closeButton}
  //           onClick={onClose}
  //         >
  //           <CloseIcon />
  //         </IconButton>
  //       ) : null}
  //     </MuiDialogTitle>
  //   );
  // });

  // const DialogContent = withStyles((styles) => ({
  //   root: {
  //     padding: theme.spacing(2),
  //   },
  // }))(MuiDialogContent);

  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const handleCloseToast = () => {
    setToastOpen({ open: false, message: "", type: "" });
  };

  const handleCreateOrder = async () => {
    try {
      setShowAppSpinner(true);

      const requestData = {
        nurseFirstName,
        nurseLastName,
        nursePhoneNumber,
        nurseEmail,
        nursePosition: nursePositionSingleValue.key,
        nurseLocationZipcode,
        nurseLicensureState: nurseLicensureValue.map((item) => item.key),
        nurseExpectedGraduation: nurseExpectedGraduationValue,
        nurseCertification: nurseCertificationValue.map((item) => item.key),
        nurseEducation: nurseEducationSingleValue.key,
        nurseAreaOfFocus: nurseAreaOfFocusValue.map((item) => item.key),
        nurseBenefits: nurseBenefitsValue.map((item) => item.key),
        nurseTimingPreference: nurseTimingPreferenceSingleValue.key,
        nurseSchedulePreference: nurseSchedulePreferenceValue.map(
          (item) => item.key,
        ),
        nurseShiftPreference: nurseShiftPreferenceValue.map((item) => item.key),
        nurseDegree: nurseDegreeValue.map((item) => item.key),
        nurseEMRExperience: nurseEMRExperienceValue.map((item) => item.key),
        nurseMinHourlyRate: nurseMinHourlyRate,
        nursePairedExperiencePosition: nursePairedExperiencePosition.map(
          (item) => item.key,
        ),
        nursePairedExperienceDuration: nursePairedExperienceDuration.map(
          (item) => item.key,
        ),
        nurseMinBonus: nurseMinBonus,
        nurseMinHourlyRate: nurseMinHourlyRate,
        nurseDegree: nurseDegreeValue.map((item) => item.key),
      };

      console.info("res", requestData);

      const createPlacementOrderResponse =
        await createPlacementOrder(requestData);
      // console.info(createPlacementOrderResponse);
      if (requestData?.status === 202) {
        setOrdResponse(requestData?.data);
        setOrdSubmit(true);
        setLastopen(true);
      } else {
        console.error("Error in create order => ", requestData);
      }

      if (createPlacementOrderResponse.status === 202) {
        setShowAppSpinner(false);
        history.push("/SignupSuccess");
      } else if (createPlacementOrderResponse.status === 409) {
        setShowAppSpinner(false);
        setToastOpen({
          open: true,
          message: "This email address is in use.",
          type: "warning",
        });
        return;
      } else if (createPlacementOrderResponse >= 400) {
        setShowAppSpinner(false);
        history.push("/Error");
      }
    } catch (err) {
      setShowAppSpinner(false);
      setToastOpen({ open: true, message: err.message, type: "error" });
      console.error("Error in create order => ", err);
    }
  };

  return (
    <Container fixed className={classes.formWrapper}>
      {showAppSpinner && <AppSpinner />}
      <Grid container>
        <Grid item xs={12} sm={6} md={6}>
          <div className={classes.wrapper}>
            <div>
              {/* {allStepsCompleted() ? (
                <div>
                  <Typography className={classes.instructions}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Button onClick={handleReset}>Reset</Button>
                </div>
              ) : ( */}
              <div>
                <div className={classes.instructions}>
                  {getStepContent(activeStep)}
                </div>

                <div className={classes.btnWrapper}>
                  {hideButton ? (
                    ""
                  ) : (
                    <Button
                      disabled={activeStep === 1}
                      onClick={handleBack}
                      className={classes.backButton}
                    >
                      Back
                    </Button>
                  )}
                  {activeStep < WizardEnum.WorkerFinalizePage && (
                    <Button
                      variant="contained"
                      color="#E1CCBE"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      Next
                    </Button>
                  )}
                  {activeStep == WizardEnum.WorkerFinalizePage && (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!nurseAgreeToContact}
                      onClick={handleCreateOrder}
                      className={classes.button}
                    >
                      Submit
                    </Button>
                  )}
                  {activeStep == 9 && (
                    <Button
                      disabled={buttondisable}
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={handleNext}
                    >
                      View Matches
                    </Button>
                  )}
                </div>
              </div>
              {/* )} */}
            </div>
          </div>
        </Grid>

        <Grid
          id="mainDiv"
          item
          xs={12}
          sm={6}
          md={6}
          className={classes.divider}
        >
          <OrdersList
            nurseFirstName={nurseFirstName}
            nurseLastName={nurseLastName}
            nurseEmail={nurseEmail}
            nursePhoneNumber={nursePhoneNumber}
            nursePosition={nursePositionSingleValue}
            nurseLocationZipcode={nurseLocationZipcode}
            nurseLicensureState={nurseLicensureValue}
            nurseCertification={nurseCertificationValue}
            nurseEducation={nurseEducationSingleValue}
            nurseAreaOfFocus={nurseAreaOfFocusValue}
            nurseBenefits={nurseBenefitsValue}
            nurseTimingPreference={nurseTimingPreferenceSingleValue}
            nurseSchedulePreference={nurseSchedulePreferenceValue}
            nurseShiftPreference={nurseShiftPreferenceValue}
            nurseDegree={nurseDegreeValue}
            nurseEMRExperience={nurseEMRExperienceValue}
            nurseMinHourlyRate={nurseMinHourlyRate}
            nursePairedExperienceDuration={nursePairedExperienceDuration}
            nursePairedExperiencePosition={nursePairedExperiencePosition}
            nurseMinimumBonus={nurseMinBonus}
            employchecked={employchecked}
          />
        </Grid>
        <GetStartedDialog open={lastopen} handleClose={handleLastClose} />
      </Grid>
      {toastOpen.open && (
        <ToasterNotification
          open={toastOpen.open}
          message={toastOpen.message}
          type={toastOpen.type}
          handleClose={handleCloseToast}
        />
      )}
    </Container>
  );
}

export default StepsWorker;
