import requestEventToken from "./requestEventToken";

// {
//   "user_id": "67acca00-56ff-4bb4-944d-fb506357a492",
//   "event_type": "SearchQuery",
//   "event_body": {
//     "context": "sure"
//   }
// }

export const writeButtonClickEvent = (context, user_hash) => {
  const details = {
    user_id: user_hash,
    event_type: "ButtonClick",
    event_body: {
      context,
    },
  };
  return writeEvent(details);
};

export const writePageViewEvent = (page, user_hash) => {
  const details = {
    user_id: user_hash,
    event_type: "PageView",
    event_body: {
      page,
    },
  };
  return writeEvent(details);
};

export const writeEvent = (details) => {
  console.info("writeEvent", details);

  return requestEventToken({
    url: "",
    method: "POST",
    data: details,
  });
};
